import { ref } from 'vue'
import axios from 'axios'

const baseURL = 'https://elifcode.herokuapp.com'
const http = axios.create({ baseURL })
const state = {
  load: ref(true),
  self: ref(null),
  test: ref(null),
  user: ref(null)
}

async function syncSelf() {
  if (!localStorage.getItem('user')) return
  const user = JSON.parse(localStorage.getItem('user'))
  const res = await http.get(`self?id=${user._id}`)
  state.self.value = { ...user, ques: res.data }
}

async function syncTest() {
  const res = await http.get('ques')
  res.data.sTime = new Date(res.data.sTime)
  res.data.eTime = new Date(res.data.eTime)
  state.test.value = res.data
}

function byScore(x, y) {
  if (x.ques.length < y.ques.length) return 1
  if (x.ques.length > y.ques.length) return -1
  return x.time < y.time ? -1 : 1
}
async function syncUser() {
  const res = await http.get('rank')
  res.data.sort(byScore)
  this.user.value = res.data
}

export default {
  ...state,
  http,
  syncSelf,
  syncTest,
  syncUser
}
