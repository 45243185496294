<template>
  <header class="d-flex align-items-center justify-content-between bg-dark p-3">
    <router-link to="/"><h3>ELIF<span class="text-mark">CODE</span></h3></router-link>
    <nav>
      <router-link class="ml-4" to="/rank">RANKS</router-link>
      <a class="ml-4" @click="user.login" v-if="!self">LOGIN</a>
      <a class="ml-4" @click="user.logout" v-else>LOGOUT</a>
    </nav>
  </header>

  <main class="container my-4" v-if="test">
    <div class="card bg-info mb-3 rounded-0">
      <div class="card-header"><h5>{{ test.name }}</h5></div>
      <div class="card-body">
        <div>NAME: {{ self ? self.name : 'Guest' }}</div>
        <div>LANG: Python 3</div>
        <div>TIME: {{ time }}</div>
        <div>INFO: {{ self ? 'Click Upload To Submit Source' : 'Login To Make Submission' }}</div>
      </div>
    </div>

    <div v-if="test.ques.length">
      <input type="file" class="d-none" @change="upload" ref="fileInput">
      <div class="btn-group btn-block mb-2">
        <button class="btn btn-outline-light" v-for="(q, i) in test.ques" :key="i"
          @click="index = i" :class="{ active: i === index }">Q{{ i + 1 }}</button>
      </div>

      <div class="card bg-dark mb-3 rounded-0">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h5>{{ test.ques[index].name }}</h5>
          <div v-if="self">
            <button class="btn btn-success" v-if="self.ques.indexOf(index) !== -1">
              <img src="../assets/check.svg" width="20">
            </button>
            <button class="btn btn-info" @click="fileInput.click()" v-else>
              <img src="../assets/upload.svg" width="20">
            </button>
          </div>
        </div>
        <div class="card-body" v-html="test.ques[index].desc"></div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, watch } from 'vue'
import snack from 'node-snackbar'
import state from '../state'
import runner from '../scripts/runner'
import time from '../scripts/time'
import user from '../scripts/user'

const index = ref(0)
const self = state.self
const test = state.test
const fileInput = ref(null)
const alert = text => snack.show({
  text: `STATUS: ${text}`,
  pos: 'bottom-center',
  actionText: 'OK',
  duration: 60000 })

syncMath()
watch([index, test], syncMath)
function syncMath() {
  setImmediate(MathJax.typeset)
}

async function process(text) {
  if (Date.now() > new Date(test.value.eTime)) return alert('CONTEST ENDED')
  const tc = test.value.ques[index.value].case
  const out = await runner(tc, text)
  const res = await state.http.post('make', {
    qid: index.value,
    uid: state.self.value._id,
    name: self.value.name,
    out: out.trim() })

  if (res.data === ':CE') return alert('CONTEST ENDED')
  if (res.data === ':WA') return alert('WRONG ANSWER')
  if (res.data === ':AC') {
    state.self.value.ques.push(index.value)
    state.syncUser()
    alert('ACCEPTED')
  } else alert('ERROR')
}

function upload() {
  if (fileInput.value.files.length === 0) return
  const file = fileInput.value.files[0]
  const reader = new FileReader()
  reader.readAsText(file)
  state.load.value = true

  reader.addEventListener('load', () => {
    fileInput.value.value = null
    process(reader.result)
      .catch(e => alert(e.toString().indexOf('Time') === -1
        ? 'RUNTIME ERROR' : 'TIME LIMIT EXCEEDED'))
      .finally(() => state.load.value = false)
  })
}
</script>
