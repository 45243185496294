import skulpt from 'skulpt'

export default async function (stdin, code) {
  let lines = 0
  let stdout = ''
  stdin = stdin.split('\n')
  const output = e => stdout += e
  const inputfun = () => stdin[lines++]
  skulpt.configure({ __future__: skulpt.python3, output, inputfun, execLimit: 100 })
  await skulpt.misceval.asyncToPromise(() => skulpt.importMainWithBody('<stdin>', false, code, true))
  return stdout
}
