import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import App from './App.vue'
import Home from './views/Home.vue'
import Rank from './views/Rank.vue'
import Test from './views/Test.vue'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    { path: '/', component: Home },
    { path: '/rank', component: Rank },
    { path: '/test', component: Test },
    { path: '/:pathMatch(.*)*', redirect: '/' }]
})

createApp(App)
  .use(router)
  .mount('#root')
