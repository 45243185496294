<template>
  <aside class="fixed-top fixed-bottom bg-dark" v-if="load">
    <div></div>
    <div></div>
  </aside>
</template>

<style scoped>
aside {
  z-index: 9999;
}

aside div {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border: 4px solid #fff;
  transform: translate(-50%, -50%);
  animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

aside div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>

<script setup>
import state from '../state'

const load = state.load
</script>
