import { ref, watch } from 'vue'
import state from '../state'

const time = ref(null)
const interval = setInterval(timer, 1000)
function makeTime(ms) {
  ms = Math.floor(ms / 1000)
  const ss = String(ms % 60).padStart(2, '0')
  ms = Math.floor(ms / 60)
  const mm = String(ms % 60).padStart(2, '0')
  ms = Math.floor(ms / 60)
  const hh = String(ms).padStart(2, '0')
  return `${hh}:${mm}:${ss}`
}

function timer(event) {
  if (!state.test.value) return
  const now = Date.now()
  const sTime = state.test.value.sTime - now
  const eTime = state.test.value.eTime - now
  if (!event && sTime < 0 && sTime >= -1000) state.syncTest()
  if (sTime > 0) time.value = `Starts In ${makeTime(sTime)}`
  else if (eTime > 0) time.value = `Ends In ${makeTime(eTime)}`
  else {
    time.value = 'Contest Ended'
    clearInterval(interval)
  }
}

watch(state.test, timer)
export default time
