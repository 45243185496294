<template>
  <spin-loader></spin-loader>
  <router-view></router-view>
</template>

<script setup>
import 'bootstrap/dist/css/bootstrap.min.css'
import 'node-snackbar/dist/snackbar.min.css'
import './assets/reset.css'

import firebase from 'firebase/app'
import 'firebase/auth'

import spinLoader from './views/Loader'
import state from './state'
const load = state.load

state.syncSelf()
state.syncTest()
state.syncUser()
addEventListener('load', () => state.load.value = false)
firebase.initializeApp({
  apiKey: 'AIzaSyBXn3iDlYWuFC9NhWfVR5UmAM_W6Lb5N2M',
  authDomain: 'elifcode-official.firebaseapp.com' })
</script>
