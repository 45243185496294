import firebase from 'firebase/app'
import state from '../state'

async function save({ user }) {
  user = { _id: user.uid, name: user.displayName }
  localStorage.setItem('user', JSON.stringify(user))
  await state.syncSelf()
}

function login() {
  const provider = new firebase.auth.GoogleAuthProvider()
  state.load.value = true
  firebase.auth().signInWithPopup(provider).then(save)
    .finally(() => state.load.value = false)
}

function logout() {
  localStorage.clear()
  state.self.value = null
  firebase.auth().signOut()
}

export default { login, logout }
