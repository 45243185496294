<template>
  <header class="d-flex align-items-center justify-content-between bg-dark p-3">
    <router-link to="/"><h3>ELIF<span class="text-mark">CODE</span></h3></router-link>
    <nav>
      <router-link class="ml-4" to="/test">HOME</router-link>
      <a class="ml-4" @click="state.syncUser">SYNC</a>
    </nav>
  </header>

  <main class="container my-4">
    <div class="list-group-item bg-dark" v-if="!user || user.length === 0">No Submissions</div>
    <div class="list-group-item bg-dark d-flex justify-content-between" v-for="(u, i) of user"
      :key="u._id" :class="{ 'text-mark': self && u._id === self._id }">
      <span>{{ i + 1 }}. {{ u.name }}</span>
      <span>{{ u.ques.length * 50 }}</span>
    </div>
  </main>
</template>

<style scoped>
.list-group-item:nth-child(even) {
  background: #343a40aa !important;
}
</style>

<script setup>
import state from '../state'

const self = state.self
const user = state.user
const test = state.test
</script>
