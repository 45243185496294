<template>
  <section class="fixed-top fixed-bottom d-flex flex-column text-center">
    <header class="d-flex align-items-center justify-content-center">
      <img src="../assets/bracket.png" width="100" class="mr-2">
      <div>
        <h1>ELIF<span class="text-mark">CODE</span></h1>
        <p>CODE IT BETTER</p>
      </div>
    </header>
  
    <nav class="d-flex">
      <router-link to="/test" class="w-100 py-3">
        <img src="../assets/trophy.svg" width="60">
        <p class="lead">CONTEST</p>
        <p class="text-info small">SHOW YOUR SKILLS</p>
      </router-link>
  
      <router-link to="/" class="w-100 py-3">
        <img src="../assets/battle.svg" width="60">
        <p class="lead">1 VS 1</p>
        <p class="text-info small">COMING SOON</p>
      </router-link>
    </nav>
  </section>
</template>

<style scoped>
section {
  background: linear-gradient(45deg, #222222cc, #22222299),
    url("../assets/background.jpg");
  background-position: bottom;
  background-size: cover;
}

header {
  flex-grow: 1;
}

nav > a {
  border-top: 1px solid #ffffff22;
}

nav > a:not(:last-child) {
  border-right: 1px solid #ffffff22;
}

nav > a:hover {
  background: #222222aa;
}
</style>
